<template>
  <div
    v-if="typeof props.quantity === 'number'"
    class="text-xs font-medium text-grey"
  >
    <p
      v-if="typeof props.quantity === 'number'"
      class="text-inherit"
      :style="paragraphStyles"
    >
      <template v-if="props.quantity > 0">
        <span class="font-medium text-primary">
          {{
            `${props.quantity} ${
              props.measurementUnit ?? labels.packageAbbreviation
            }`
          }}
        </span>
        - 24h
      </template>

      <template v-if="props.quantity <= 0 && props.availabilityLabel">
        {{ props.availabilityLabel }}
      </template>
    </p>
  </div>
</template>

<script setup lang="ts">
import type { M2ProductDeliveryTimeProps } from './M2ProductDeliveryTime.types'

const props = defineProps<M2ProductDeliveryTimeProps>()

const { t } = useI18n()

const labels = computed(() => {
  return {
    packageAbbreviation: t('ecom_pck'),
  }
})

const paragraphStyles = {
  fontSize: 'inherit',
  lineHeight: 'inherit',
}
</script>
